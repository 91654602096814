import { ElementType } from 'react';

import Error403 from 'assets/icons/error403.svg';
import Error404 from 'assets/icons/error404.svg';
import Error500 from 'assets/icons/error500.svg';
import Error503 from 'assets/icons/error503.svg';
import { MessageDescriptor } from 'utils/intl';

import { ErrorCode } from './ErrorPage.types';
import messages from './ErrorPage.messages';

export const errorMessageMap: Record<ErrorCode, MessageDescriptor> = {
  403: messages.message403,
  404: messages.message404,
  410: messages.message404,
  500: messages.message500,
  503: messages.message503,
};

export const errorDescriptionMap: Record<ErrorCode, MessageDescriptor> = {
  403: messages.description403,
  404: messages.description404,
  410: messages.description404,
  500: messages.description500,
  503: messages.description404,
};

export const errorIconMap: Record<ErrorCode, ElementType> = {
  403: Error403,
  404: Error404,
  410: Error404,
  500: Error500,
  503: Error503,
};

export const defaultErrorCode = 500;
