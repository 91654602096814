import { createContext } from 'react';

import { GetVariantsResults } from 'graphql/queries/GetVariants.query';
import { GetProductsResults } from 'graphql/queries/GetProducts.query';
import { GetCategoriesResults } from 'graphql/queries/GetCategories.query';
import { GetCardVariantsResults } from 'graphql/queries/GetCardsVariants.query';
import { GetArticlesByIdsResult } from 'graphql/queries/GetArticlesByIds.query';
import { SearchItems } from 'graphql/queries/SearchItemsWithAttributes.query';
import { GetMediaResults } from 'graphql/queries/GetMedia.query';
import { GetArticlesPhotosResults } from 'graphql/queries/GetArticlesPhotos.query';
import { GetArticlesVideosResults } from 'graphql/queries/GetArticlesVideos.query';

export type DescriptionDataContextType = {
  media: GetMediaResults['media'];
  variants: GetVariantsResults['variants'];
  products: GetProductsResults['products'];
  categories: GetCategoriesResults['categories'];
  cardVariants: GetCardVariantsResults['cardVariants'];
  descriptionArticles: GetArticlesByIdsResult['descriptionArticles'];
  descriptionArticlesPhotos: GetArticlesPhotosResults['descriptionArticlesPhotos'];
  descriptionArticlesVideos: GetArticlesVideosResults['descriptionArticlesVideos'];
  categoryVariants: Record<number, SearchItems>;
  productVariants: Record<number, SearchItems>;
};

export const DescriptionDataContext = createContext<DescriptionDataContextType>({
  media: [],
  variants: [],
  products: [],
  categories: [],
  cardVariants: [],
  categoryVariants: [],
  productVariants: [],
  descriptionArticles: [],
  descriptionArticlesPhotos: [],
  descriptionArticlesVideos: [],
});
