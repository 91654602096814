import { toggleAnalytics } from 'consts/keyboardShortcuts';
import analyticsStorage from 'storages/analytics.storage';
import { useIntl } from 'utils/intl';
import useKeyboardShortcut from 'hooks/useKeyboardShortcut';

import messages from './AnalyticsKeyPress.messages';

const AnalyticsKeyPress = (): JSX.Element => {
  const intl = useIntl();

  useKeyboardShortcut(toggleAnalytics, async ({ showMessage }) => {
    const { isTrackingDisabled } = analyticsStorage.get();
    analyticsStorage.set({ isTrackingDisabled: !isTrackingDisabled });
    showMessage('info', intl.formatMessage(isTrackingDisabled ? messages.trackingEnabled : messages.trackingDisabled));
  });

  return <></>;
};

export default AnalyticsKeyPress;
