type StorageType = 'localStorage' | 'sessionStorage';

abstract class Storage<T> {
  private storageType: StorageType;

  readonly key!: string;

  readonly defaultResult!: T;

  constructor(storageType: StorageType = 'localStorage') {
    this.storageType = storageType;
  }

  get(): T {
    try {
      const stringItem = globalThis[this.storageType].getItem(this.key);
      const result = stringItem ? JSON.parse(stringItem) : this.defaultResult;
      return result;
    } catch {
      return this.defaultResult;
    }
  }

  set(value: T): void {
    globalThis[this.storageType].setItem(this.key, JSON.stringify(value));
  }

  clear(): void {
    globalThis[this.storageType].removeItem(this.key);
  }
}

export default Storage;
