import { State, Action } from './fetchReducer.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const initialState: State<any> = {
  data: undefined,
  headers: undefined,
  error: undefined,
  loading: true,
  loaded: false,
  timestamp: undefined,
};

const reducer = <D>(state: State<D>, action: Action<D>): State<D> => {
  switch (action.type) {
    case 'FETCH/START':
      return {
        ...state,
        error: undefined,
        loading: true,
      };
    case 'FETCH/SUCCESS':
      return {
        data: action.payload?.data,
        headers: action.payload?.headers,
        error: undefined,
        loading: false,
        loaded: true,
        timestamp: new Date().getTime(),
      };
    case 'FETCH/ERROR':
      return {
        ...state,
        error: action.payload?.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
