import Price from 'types/Price';
import { EventParams } from 'types/Analytics';
import { ProductInCart } from 'types/ProductInCart';
import { ShipmentMethod } from 'types/models/ShipmentMethod';
import { getItems } from 'utils/analytics';

export const getAddShippingInfoPayload = (
  cartPrice: Price,
  products: ProductInCart[],
  shippingMethod: ShipmentMethod['name'] = ''
): EventParams['add_shipping_info'] => ({
  currency: cartPrice.currency,
  value: cartPrice.amount / 100,
  shipping_tier: shippingMethod,
  // TODO: add coupon field
  items: getItems(
    products.map((item) => ({
      ...item,
      grossPricePerUnit: item.grossPricePerUnit.amount,
    }))
  ),
});
