import { useContext, useEffect } from 'react';

import { SnackbarContext, SnackbarContextType } from '@providers/SnackbarProvider';
import useMultiKeyPress, { areKeysPressed } from 'hooks/useMultiKeyPress';

type Shortcut = string[];

type OnKeyboardShortcut = ({ showMessage }: { showMessage: SnackbarContextType['showMessage'] }) => Promise<void>;

const useKeyboardShortcut = (shortcuts: Shortcut | Shortcut[], onKeyboardShortcut: OnKeyboardShortcut): void => {
  const { showMessage } = useContext(SnackbarContext);
  const { keysPressed } = useMultiKeyPress();

  const isShortcutPressed = Array.isArray(shortcuts?.[0])
    ? shortcuts.some((shortcutKeys) => areKeysPressed(shortcutKeys as Shortcut, keysPressed))
    : areKeysPressed(shortcuts as Shortcut, keysPressed);

  useEffect(() => {
    if (isShortcutPressed) {
      onKeyboardShortcut({ showMessage });
    }
  }, [isShortcutPressed]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useKeyboardShortcut;
