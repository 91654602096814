import { ElementType } from 'react';

import { Variant } from './Typography.types';

export const typographyVariantsMap: Record<Variant, ElementType> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  body1: 'p',
  body2: 'p',
  body3: 'p',
  caption1: 'span',
  caption2: 'span',
  label: 'span',
  huge: 'div',
  xHuge: 'div',
};
