import { useState, useEffect } from 'react';

import getUniqueFlatArray from 'utils/getUniqueFlatArray';

const useMultiKeyPress = (): { keysPressed: string[] } => {
  const [keysPressed, setKeyPressed] = useState<string[]>([]);

  const downHandler = ({ key }: KeyboardEvent) => {
    setKeyPressed((currentKeys) => getUniqueFlatArray([...currentKeys, key]));
  };

  const upHandler = ({ key }: KeyboardEvent) => {
    setKeyPressed((currentKeys) => currentKeys.filter((pressedKey) => pressedKey !== key));
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return { keysPressed };
};

export const areKeysPressed = (keys: string[] = [], keysPressed: string[] = []): boolean =>
  keys.length === keysPressed.length && keys.every((key) => keysPressed.includes(key));

export default useMultiKeyPress;
