const intl = {
  locale: 'pl',
  defaultLocale: 'pl',
  languageTag: 'pl-PL',
  formats: {
    amount: {
      style: 'currency',
      currency: 'PLN',
    },
  },
};

const viewDateFormat = 'dd.MM.yyyy';

export default { intl, viewDateFormat };
