import { CompareVariantsRoute } from 'api/variants/compare';
import { GetVariantsPromotedRoute } from 'api/variants/promoted';
import { GetVariantAttributesRoute } from 'api/variants/[id]/attributes';
import { GetFlatVariantAttributesRoute } from 'api/variants/[id]/flatAttributes';
import { GetVariantBreadcrumbsRoute } from 'api/variants/[id]/breadcrumbs';
import { GetUserRatingRoute, InsertUserRatingRoute } from 'api/variants/[id]/rating';
import { GetVariantSelectorRoute } from 'api/variants/[id]/selector';
import ApiRoute from 'utils/apiRoute';

export const compareVariantsRoute: CompareVariantsRoute = new ApiRoute('/variants/compare', 'GET');
export const getVariantsPromotedRoute: GetVariantsPromotedRoute = new ApiRoute('/variants/promoted', 'GET');

export const getVariantAttributesRoute: GetVariantAttributesRoute = new ApiRoute('/variants/:id/attributes', 'GET');
export const getFlatVariantAttributesRoute: GetFlatVariantAttributesRoute = new ApiRoute(
  '/variants/:id/flatAttributes',
  'GET'
);
export const getVariantBreadcrumbsRoute: GetVariantBreadcrumbsRoute = new ApiRoute('/variants/:id/breadcrumbs', 'GET');
export const getUserRatingRoute: GetUserRatingRoute = new ApiRoute('/variants/:id/rating', 'GET');
export const insertUserRatingRoute: InsertUserRatingRoute = new ApiRoute('/variants/:id/rating', 'PUT');
export const getVariantSelectorRoute: GetVariantSelectorRoute = new ApiRoute('/variants/:id/selector', 'GET');
