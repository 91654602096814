type ErrorHandler = (error: Error) => void;

let captureExceptionFunc: ErrorHandler | null = null;

export const setCaptureExceptionFunc = (handler: ErrorHandler) => {
  captureExceptionFunc = handler;
};

export const captureException: ErrorHandler = (err) => {
  if (typeof captureExceptionFunc === 'function') {
    captureExceptionFunc(err);
  }
};
