import Storage from 'storages/Storage';

type AnalyticsStorageData = {
  isTrackingDisabled: boolean;
};

class AnalyticsStorage extends Storage<AnalyticsStorageData> {
  readonly key = 'analytics';

  readonly defaultResult: AnalyticsStorageData = {
    isTrackingDisabled: true,
  };

  get() {
    return this.defaultResult;
  }
}

export default new AnalyticsStorage();
