import slug from 'slugg';

import config from 'config';
import { ImagePreset } from 'types/ImagePreset';
import { SrcSetConfig } from 'types/SrcSetConfig';

export type ResolveConfig = {
  extension?: string;
  overrideExtension?: string;
  preset: ImagePreset;
  name?: string;
  productName?: string;
  description?: string | null;
  srcSetConfig?: SrcSetConfig[];
  width?: number;
  height?: number;
};

export type ImageExtension = 'jpg' | 'png' | 'svg';

export type PresetExtensionMap = Record<ImagePreset, ImageExtension>;

const { imagesHostUrl } = config;

const presetExtensionMap: PresetExtensionMap = {
  banner_big: 'jpg',
  banner_big_2x: 'jpg',
  banner_medium: 'jpg',
  banner_medium_2x: 'jpg',
  banner_small: 'jpg',
  banner_small_2x: 'jpg',
  p_thumb_1: 'png',
  p_thumb_1_2x: 'png',
  p_thumb_2: 'png',
  p_thumb_2_2x: 'png',
  p_thumb_3: 'png',
  p_thumb_3_2x: 'png',
  p_thumb_4: 'png',
  p_thumb_4_2x: 'png',
  p_thumb_5: 'png',
  p_thumb_5_2x: 'png',
  p_thumb_6: 'png',
  p_thumb_6_2x: 'png',
  p_thumb_1x1: 'png',
  p_thumb_4x3: 'png',
  p_thumb_16x9: 'png',
  p_full_480: 'png',
  p_full_960: 'png',
  p_full_1440: 'png',
  p_full_1440_jpg: 'jpg',
  p_full_1920: 'png',
  p_full_3840: 'png',
  p_main: 'png',
  p_main_2x: 'png',
  p_main_theone: 'jpg',
  testimonial: 'png',
  testimonial_2x: 'png',
  customer_logo: 'svg',
  customer_logo_2x: 'svg',
  footer_logo: 'png',
  footer_logo_2x: 'png',
  prize: 'svg',
  prize_2x: 'svg',
  main_menu_icon: 'svg',
  main_menu_icon_2x: 'svg',
  category_icon: 'svg',
  category_icon_2x: 'svg',
  category_banner: 'png',
  category_banner_2x: 'png',
  article_thumbnail: 'png',
  article_thumbnail_2x: 'png',
  article_thumbnail_main: 'png',
  article_thumbnail_main_2x: 'png',
  avatar: 'jpg',
  og: 'jpg',
  i: 'png',
  i_2x: 'png',
  svg: 'svg',
};

const getProductName = (productName: string, description?: string | null): string =>
  `${slug(productName)}${description ? '-' : ''}${slug(description || '')}`;

export const getExtension = (preset: ImagePreset): string => presetExtensionMap[preset];

const resolveImageUrl = (
  {
    extension = 'jpg',
    overrideExtension,
    preset = 'p_thumb_1',
    productName = '',
    name = '',
    description,
  }: ResolveConfig,
  { suffix = '', appUrl = '' } = {}
): string =>
  [
    appUrl ? `${appUrl}${imagesHostUrl}` : imagesHostUrl,
    extension,
    name,
    `${preset}${suffix}`,
    `${getProductName(productName, description)}.${overrideExtension || getExtension(preset)}`,
  ].join('/');

export default resolveImageUrl;
