import keysOf from 'utils/keysOf';

export type RefererType = 'google' | 'ceneo' | 'search' | 'bing' | 'gmc' | 'unknown';

const refererTypesMap: Partial<Record<RefererType, string>> = {
  google: 'gclid',
  ceneo: 'ceneo_cid',
  search: 'google',
  bing: 'bing',
  gmc: 'srsltid',
};

const searchEnginesUrlParts: RefererType[] = ['search', 'bing'];

const getRefererByUrl = (url: string): RefererType =>
  searchEnginesUrlParts.find((key) => new RegExp(refererTypesMap[key] || '').test(url)) || 'unknown';

const getRefererType = (query: Record<string, unknown>, referer: string): RefererType | undefined =>
  keysOf(refererTypesMap).find((type) => !!query[refererTypesMap[type] || '']) || getRefererByUrl(referer);

export default getRefererType;
