import { useEffect, ReactNode } from 'react';

import useBoolean from 'hooks/useBoolean';

type Props = {
  onSSR?: () => JSX.Element;
  children: ReactNode;
};

const NoSSR = ({ children, onSSR = () => <span /> }: Props): JSX.Element => {
  const [canRender, { on }] = useBoolean(false);

  useEffect(on, []); // eslint-disable-line react-hooks/exhaustive-deps

  return canRender ? <>{children}</> : onSSR();
};

export default NoSSR;
