import { forwardRef } from 'react';
import classNames from 'classnames';

import createStyleVariables from 'utils/createStyleVariables';
import { typographyVariantsMap } from './Typography.consts';
import classes from './Typography.module.scss';
import Props from './Typography.types';

const Typography = forwardRef<HTMLElement, Props>(
  (
    {
      align,
      className,
      renderAs,
      display,
      variant,
      children,
      weight,
      transform,
      lineHeight,
      ellipsisLines,
      wordBreak,
      withEllipsis,
      style,
      ...rest
    },
    ref
  ) => {
    const Component = renderAs || typographyVariantsMap[variant];

    const localStyle = createStyleVariables({
      align,
      display,
      transform,
      wordBreak,
      weight,
      lineHeight,
    });

    const finalStyle = { ...localStyle, ...style };

    return (
      <Component
        {...rest}
        ref={ref}
        style={
          ellipsisLines
            ? { ...createStyleVariables({ ellipsisLines, wordBreak: 'break-word' }), ...finalStyle }
            : finalStyle
        }
        className={classNames(className, classes.wrapper, classes[variant], {
          [classes.align]: align,
          [classes.display]: display,
          [classes.transform]: transform,
          [classes.weight]: weight,
          [classes.lineHeight]: lineHeight,
          [classes.ellipsis]: withEllipsis || ellipsisLines,
        })}
      >
        {children}
      </Component>
    );
  }
);

export default Typography;
