import { createContext } from 'react';

import { FormValues } from '@pages/CheckoutDeliveryPage/CheckoutDeliveryPage.types';
import { UserTypeId } from '@components/CheckoutListOption';
// import { defaultCreditCardValues } from '@components/CreditCard/CreditCard.consts';
// import { FormValues as CreditCardFormValues } from '@components/CreditCard/CreditCard.types';
import { ShipmentId } from 'types/models/ShipmentMethod';
import { PaymentId } from 'types/models/PaymentMethod';
import SetState from 'types/SetState';

import { GoToSummary } from './CheckoutProvider.types';

export type CheckoutContextType = {
  activeUserTypeId?: UserTypeId;
  activeShippingMethodId?: ShipmentId;
  activePaymentMethodId?: PaymentId;
  submittingOrder: boolean;
  activeCompanyDataId?: number;
  activeShipmentId?: number;
  activeBankId?: number;
  initialValues: FormValues;
  // initialCreditCardValues: CreditCardFormValues;
  setInitialValues: SetState<FormValues>;
  // setInitialCreditCardValues: SetState<CreditCardFormValues>;
  setActiveUserTypeId: SetState<UserTypeId>;
  setActiveShippingMethodId: SetState<ShipmentId>;
  setActivePaymentMethodId: SetState<PaymentId>;
  setActiveBankId: SetState<number | undefined>;
  setActiveCompanyDataId: SetState<number | undefined>;
  setActiveShipmentId: SetState<number | undefined>;
  setSubmittingOrder: SetState<boolean>;
  goToSummary: GoToSummary;
};

export const defaultValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  vat: '',
  companyName: '',
  street: '',
  houseNumber: '',
  apartmentNumber: '',
  postalCode: '',
  locality: '',
  withRegister: false,
  password: '',
  repeatPassword: '',
  additionalCompanyName: '',
  additionalStreet: '',
  additionalHouseNumber: '',
  additionalApartmentNumber: '',
  additionalPostalCode: '',
  additionalLocality: '',
  additionalFirstName: '',
  additionalLastName: '',
  additionalEmail: '',
  additionalPhoneNumber: '',
  comments: '',
  invoicePrintableNotes: '',
  invoiceEmail: '',
  withInvoiceEmail: false,
  showCustomAddress: false,
  withProforma: false,
  checkAll: false,
  termsOfUse: false,
  commercialInfo: false,
  ceneo: false,
};

export const CheckoutContext = createContext<CheckoutContextType>({
  submittingOrder: false,
  initialValues: defaultValues,
  // initialCreditCardValues: defaultCreditCardValues,
  setInitialValues: () => {},
  // setInitialCreditCardValues: () => {},
  setActiveUserTypeId: () => {},
  setActiveShippingMethodId: () => {},
  setActivePaymentMethodId: () => {},
  setActiveCompanyDataId: () => {},
  setActiveShipmentId: () => {},
  setActiveBankId: () => {},
  goToSummary: () => {},
  setSubmittingOrder: () => {},
});
