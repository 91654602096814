import classNames from 'classnames';

import PhoneIcon from 'assets/icons/phone.svg';
import Typography, { Variant, Weight } from '@components/atoms/Typography';
import formatPhone, { PhoneVariant } from 'utils/formatPhone';

import classes from './PhoneNumber.module.scss';

export type Props = {
  className?: string;
  phoneClassName?: string;
  tel: string | number;
  variant: PhoneVariant;
  typographyVariant?: Variant;
  lineHeight?: string;
  prefix?: string;
  weight?: Weight;
  withoutIcon?: boolean;
};

const PhoneNumber = ({
  tel,
  variant,
  className,
  phoneClassName,
  prefix = '+48',
  withoutIcon = false,
  typographyVariant = 'body2',
  lineHeight,
  weight = '700',
}: Props): JSX.Element => {
  const formattedNumber = formatPhone(tel, variant, prefix);

  return (
    <a href={`tel:${prefix}${tel}`} className={classNames(classes.wrapper, className)}>
      {!withoutIcon && <PhoneIcon />}
      <Typography
        className={phoneClassName}
        variant={typographyVariant}
        weight={weight}
        lineHeight={lineHeight}
        renderAs="span"
      >
        {formattedNumber}
      </Typography>
    </a>
  );
};

export default PhoneNumber;
