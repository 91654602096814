import classNames from 'classnames';

import classes from './ProgressBar.module.scss';

type Props = {
  fixed?: boolean;
  visible?: boolean;
  variant?: 'primary' | 'secondary';
};

const ProgressBar = ({ fixed = false, visible = true, variant = 'primary' }: Props): JSX.Element => (
  <div
    className={classNames(classes.container, {
      [classes.visible]: visible,
      [classes.fixed]: fixed,
      [classes.absolute]: !fixed,
    })}
  >
    <div className={classNames(classes.value, classes[variant])} />
  </div>
);

export default ProgressBar;
