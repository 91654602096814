// import { useContext } from 'react';

// import { analyticsTrackRoute } from 'apiRoutes/analytics';
// import { ConfigContext } from '@providers/ConfigProvider';
// import analyticsStorage from 'storages/analytics.storage';
import type { TrackPageView, TrackLink, TrackEvent } from 'utils/analytics';
// import { trackPageView, trackEvent, trackLink,  } from 'utils/analytics';
// import useFetch from 'hooks/useFetch';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OmitLastParameter<Func extends (...params: any[]) => void> = (
  ...params: OmitLast<Parameters<Func>>
) => ReturnType<Func>;

const useTrack = () => {
  // const { uaTrackingId } = useContext(ConfigContext);
  // const [track] = useFetch(analyticsTrackRoute);

  // const trackApiPageView: OmitLastParameter<TrackPageView> = (url, previousUrl): void => {
  //   try {
  //     const { isTrackingDisabled } = analyticsStorage.get();

  //     trackPageView(url, previousUrl, uaTrackingId);

  //     if (!isTrackingDisabled) {
  //       track({ type: 'page_view', payload: { url, previous_url: previousUrl } });
  //     }
  //   } catch {} // eslint-disable-line no-empty
  // };

  // const trackApiEvent: OmitLastParameter<TrackEvent> = (category, event, params) => {
  //   try {
  //     const { isTrackingDisabled } = analyticsStorage.get();

  //     trackEvent(category, event, params, uaTrackingId);

  //     if (!isTrackingDisabled) {
  //       track({ type: event, payload: params });
  //     }
  //   } catch {} // eslint-disable-line no-empty
  // };

  // const trackApiLink: OmitLastParameter<TrackLink> =
  //   (category, event, params) =>
  //   (callback = () => {}) => {
  //     try {
  //       const { isTrackingDisabled } = analyticsStorage.get();

  //       if (!isTrackingDisabled) {
  //         track({ type: event, payload: params });
  //       }
  //     } catch {} // eslint-disable-line no-empty
  //     return trackLink(category, event, params, uaTrackingId)(callback);
  //   };

  const trackApiPageView: OmitLastParameter<TrackPageView> = () => () => {};
  const trackApiEvent: OmitLastParameter<TrackEvent> = () => () => {};
  const trackApiLink: OmitLastParameter<TrackLink> = () => (callback) => callback();

  return { trackPageView: trackApiPageView, trackEvent: trackApiEvent, trackLink: trackApiLink };
};

export default useTrack;
