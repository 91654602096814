import { useState } from 'react';

import breakpoints from 'theme/breakpoints';
import Breakpoint from 'types/Breakpoint';
import isBrowser from 'utils/isBrowser';
import useEventListener from 'hooks/useEventListener';

const breakpointsHelper = {
  up: (mediaQuery: Breakpoint): string => `(min-width: ${breakpoints[mediaQuery]}px)`,
  down: (mediaQuery: Breakpoint): string => `(max-width: ${breakpoints[mediaQuery]}px)`,
};

const checkQuery = (mediaQuery: string): boolean => isBrowser && window.matchMedia(mediaQuery).matches;

/**
 * !!! DO NOT USE IT FOR CONDITIONAL RENDERING !!!
 */
const useMediaQuery = (breakpoint: Breakpoint, functionName: 'up' | 'down' = 'down'): boolean => {
  const mediaQuery = breakpointsHelper[functionName](breakpoint);
  const [isInQuery, setIsInQuery] = useState(checkQuery(mediaQuery));

  useEventListener('resize', () => {
    setIsInQuery(checkQuery(mediaQuery));
  });

  return isInQuery;
};

export default useMediaQuery;
