import classNames from 'classnames';

import LoaderLightIcon from 'assets/icons/loader-light.svg';
import LoaderDarkIcon from 'assets/icons/loader-dark.svg';

import classes from './Loader.module.scss';

const defaultSize = {
  width: 17,
  height: 17,
};

type Props = {
  isDark?: boolean;
  size?: number;
};

const Loader = ({ isDark = false, size }: Props): JSX.Element => {
  const Component = isDark ? LoaderDarkIcon : LoaderLightIcon;

  return (
    <Component
      className={classNames(classes.loader, { [classes.dark]: isDark })}
      {...(size ? { width: size, height: size } : defaultSize)}
    />
  );
};

export default Loader;
