import {
  biznesoweContact,
  dellowoContact,
  premiumStationContact,
  premiumSerwerContact,
  coconContact,
  firewallContact,
  ruggedContact,
} from 'config/shops';
import ContactConfig from 'types/ContactConfig';
import keysOf from 'utils/keysOf';
import confidentFind from 'utils/confidentFind';

import { ShopKey, ShopPresence, Props } from './ConfigProvider.types';
import { ConfigContext } from './ConfigProvider.context';

const { Provider } = ConfigContext;

const shopsMap: Record<ShopKey, string> = {
  biznesowe: '1',
  dellowo: '2',
  premiumStation: '3',
  premiumSerwer: '4',
  cocon: '5',
  firewall: '6',
  rugged: '7',
};

export const allShops = keysOf(shopsMap);

export const shopsFieldsMap: Record<ShopKey, string> = {
  biznesowe: 'biznesowe',
  dellowo: 'dellowo',
  premiumStation: 'premium_station',
  premiumSerwer: 'premium_serwer',
  cocon: 'cocon',
  firewall: 'firewall',
  rugged: 'rugged',
};

export const shopsContact: Record<ShopKey, ContactConfig> = {
  biznesowe: biznesoweContact,
  dellowo: dellowoContact,
  premiumStation: premiumStationContact,
  premiumSerwer: premiumSerwerContact,
  cocon: coconContact,
  firewall: firewallContact,
  rugged: ruggedContact,
};

export const getShopKey = (shopId?: string) => confidentFind(keysOf(shopsMap), (key) => shopId === shopsMap[key]);

const ConfigProvider = ({ config, children }: Props): JSX.Element => {
  const shopPresenceMap: ShopPresence = keysOf(shopsMap)
    .map((key) => {
      const [firstLetter, ...rest] = key;
      return {
        [`is${[firstLetter.toUpperCase(), ...rest].join('')}`]: config.shopId === shopsMap[key],
      } as ShopPresence;
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {} as ShopPresence);

  const shopKey = getShopKey(config.shopId);

  return <Provider value={{ ...config, ...shopPresenceMap, contact: shopsContact[shopKey] }}>{children}</Provider>;
};

export default ConfigProvider;
