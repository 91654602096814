import mapValues from 'utils/mapValues';

export type MessageDescriptor = {
  id?: string | number;
  description?: string | object;
  defaultMessage?: string;
};

const defineMessages = <T extends Record<string, string> = Record<string, string>>(
  messages: T
): { [key in keyof T]: MessageDescriptor } =>
  mapValues(messages, (message) => ({
    id: Math.random(), // TODO: generate unique, but static id
    defaultMessage: message,
  }));

export default defineMessages;
