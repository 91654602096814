import { createContext, ReactNode, useCallback, useMemo } from 'react';
import { IntlShape } from 'utils/intl';

import { ContextValues, Props } from './IntlProvider.types';
import { interpolateVariables } from './IntlProvider.utils';

const IntlContext = createContext<ContextValues>({
  locale: undefined,
  defaultLocale: undefined,
  intl: {
    formatMessage: () => '',
    formatMessageWithParts: () => [],
    formatRelativeTime: () => '',
  },
});

const IntlProvider = ({ locale, defaultLocale, messages, children }: Props): JSX.Element => {
  const formatMessage: IntlShape['formatMessage'] = useCallback(
    (message, variables = {}) =>
      interpolateVariables(
        messages?.[message.id as string] || message.defaultMessage || '',
        variables,
        false
      ) as string,
    [locale, messages] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const formatMessageWithParts: IntlShape['formatMessageWithParts'] = useCallback(
    (message, variables = {}) =>
      interpolateVariables(
        messages?.[message.id as string] || message.defaultMessage || '',
        variables,
        true
      ) as ReactNode[],
    [locale, messages] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const formatRelativeTime: IntlShape['formatRelativeTime'] = useCallback(
    (value, format, options, fallbackDate = '') =>
      /* eslint-disable compat/compat */
      Intl?.RelativeTimeFormat
        ? new Intl.RelativeTimeFormat(locale, { numeric: 'auto', ...options }).format(value, format)
        : fallbackDate,
    [locale, messages] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const intl = useMemo(
    () => ({ formatMessage, formatMessageWithParts, formatRelativeTime }),
    [formatMessage, formatMessageWithParts, formatRelativeTime]
  );

  return <IntlContext.Provider value={{ locale, defaultLocale, intl }}>{children}</IntlContext.Provider>;
};

export default IntlProvider;
export { IntlContext };
