import { ReactNode } from 'react';

import { Holiday } from 'types/models/Holiday';

import { HolidaysContext } from './HolidaysProvider.context';

const { Provider } = HolidaysContext;

type Props = {
  holidays?: Holiday[];
  children: ReactNode;
};

const HolidaysProvider = ({ holidays = [], children }: Props): JSX.Element => (
  <Provider value={{ holidays }}>{children}</Provider>
);

export default HolidaysProvider;
