import { Item, ProductItem } from 'types/Analytics';
import getPlainText from 'utils/getPlainText';

export const getItems = (items: ProductItem[]): Item[] =>
  items?.map((item) => ({
    item_id: `${item.variant.id}`,
    item_name: getPlainText(item.variant.name),
    currency: item.pricePerUnit.currency,
    item_category: item.variant.product.type?.name,
    item_category2: item.variant.product.manufacturer?.name,
    item_category3: item.variant.product.family?.name || item.variant.product.name,
    item_category4: item.variant.product.family?.name ? item.variant.product.name : undefined,
    price: item.grossPricePerUnit / 100,
    quantity: item.quantity,
    // TODO: add more fields
  })) || [];

export default getItems;
