const resolveSentryEnv = async () => {
  try {
    const { sentryEnv } = await (await fetch('/api/environment')).json();
    return sentryEnv;
  } catch (e) {
    return 'unknown';
  }
};

export default resolveSentryEnv;
