import shadows from 'styles/exports/shadows.module.scss';
import sliderClasses from 'styles/exports/slider.module.scss';

export const NOSCRIPT_CLASSES = {
  IMAGE: 'image',
  IMAGE_SKELETON: 'image-skeleton',
  SORT_PRODUCT: 'sort-product',
  SHOW: 'show',
  HIDE: 'hide',
  COLLAPSIBLE_CONTENT: 'collapsible-content',
  JUSTIFY_CONTENT: 'justify-content',
};

export const noscriptCss = `
  .${NOSCRIPT_CLASSES.IMAGE} {
    opacity: 1 !important;
  }
  .${NOSCRIPT_CLASSES.IMAGE_SKELETON} {
    display: none !important;
  }
  .${NOSCRIPT_CLASSES.SORT_PRODUCT}:focus-within {
    box-shadow: ${shadows.default /* eslint-disable-line css-modules/no-undef-class */};
  }
  .${NOSCRIPT_CLASSES.SORT_PRODUCT}:focus-within a {
    display: block !important;
  }
  .${NOSCRIPT_CLASSES.SHOW} {
    display: block !important;
  }
  .${NOSCRIPT_CLASSES.HIDE} {
    display: none !important;
  }
  .${sliderClasses.slider} {
    overflow-x: scroll !important;
    scroll-snap-type: x mandatory;
  }
  .${sliderClasses.slider} > div {
    scroll-snap-align: start;
  }
  .${sliderClasses.arrow}, .${sliderClasses.dots} {
    display: none !important;
  }
  .${NOSCRIPT_CLASSES.COLLAPSIBLE_CONTENT} {
    height: auto !important;
  }
  .${NOSCRIPT_CLASSES.JUSTIFY_CONTENT} {
    justify-content: center;
  }
`;
