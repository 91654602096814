import Price from 'types/Price';
import { EventParams } from 'types/Analytics';
import { ProductInCart } from 'types/ProductInCart';
import { PaymentMethod } from 'types/models/PaymentMethod';
import { getItems } from 'utils/analytics';

export const getAddPaymentInfoPayload = (
  cartPrice: Price,
  products: ProductInCart[],
  paymentMethod: PaymentMethod['name'] = ''
): EventParams['add_payment_info'] => ({
  currency: cartPrice.currency,
  value: cartPrice.amount / 100,
  payment_type: paymentMethod,
  // TODO: add coupon field
  items: getItems(
    products.map((item) => ({
      ...item,
      grossPricePerUnit: item.grossPricePerUnit.amount,
    }))
  ),
});
