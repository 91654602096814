import { CSSProperties } from 'react';

import keysOf from 'utils//keysOf';

const createStyleVariables = (object: Record<string, string | number | undefined>): CSSProperties => {
  const keys = keysOf(object);
  const result: Record<string, string | number | undefined> = {};

  keys.forEach((key) => {
    if (object[key] || object[key] === 0) {
      result[`--${key}`] = object[key];
    }
  });

  return result;
};

export default createStyleVariables;
