import { memo, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import config from 'config';
import CompareIcon from 'assets/icons/compare.svg';
import { SimpleVariant } from 'types/Variant';
import { CompareContext } from '@providers/CompareProvider';
import { UserContext } from '@providers/UserProvider';
import { compareVariantsRoute } from 'apiRoutes/variants';
import Loader from '@components/atoms/Loader';
import CompareFloatingPanel from '@components/CompareFloatingPanel';

import CompareDrawer from '@components/CompareDrawer';
import NoSSR from '@misc/NoSSR';
import createStyleVariables from 'utils/createStyleVariables';
import confidentFind from 'utils/confidentFind';
import compact from 'utils/compact';
import useMediaQuery from 'hooks/useMediaQuery';
import useFetch from 'hooks/useFetch';

import classes from './CompareFab.module.scss';

const { routes } = config;

type Props = {
  bottomOffset?: number;
};

const CompareFab = ({ bottomOffset }: Props): JSX.Element => {
  const { ids, count, isRefreshed } = useContext(CompareContext);
  const { isSessionRefreshed } = useContext(UserContext);
  const isTablet = useMediaQuery('small');
  const router = useRouter();

  const [variants, setVariants] = useState<SimpleVariant[]>([]);

  const isComparePage = router.route === routes.compare.href;

  const [getData, { loading }] = useFetch(compareVariantsRoute);

  useEffect(() => {
    const fetchData = async () => {
      if (isSessionRefreshed && isRefreshed && !isComparePage && ids.length) {
        const { data } = await getData({ ids });
        const { variants: dataVariants } = data || {};

        if (dataVariants) {
          setVariants(dataVariants);
        }
      }
    };

    fetchData();
  }, [ids.length, isSessionRefreshed, isRefreshed, isComparePage]); // eslint-disable-line react-hooks/exhaustive-deps

  const icon = (
    <div className={classes.iconWrapper}>
      <CompareIcon className={classes.icon} />
      {loading ? (
        <div className={classes.loader}>
          <Loader />
        </div>
      ) : (
        <div className={classes.sticker}>{count}</div>
      )}
    </div>
  );

  const sortedVariants = useMemo(
    () => compact(ids.map((id) => confidentFind(variants, (variant) => variant.id === id))),
    [ids, variants]
  );

  return (
    <NoSSR>
      <div
        className={classes.container}
        style={createStyleVariables({ compareFabBottomOffset: `${bottomOffset || 0}px` })}
      >
        {isTablet ? (
          <CompareDrawer
            className={classNames(classes.fab, { [classes.visible]: count && !isComparePage })}
            isComparePage={isComparePage}
            variants={sortedVariants}
            icon={icon}
          />
        ) : (
          <CompareFloatingPanel
            className={classNames(classes.floatingPanel, { [classes.visible]: count && !isComparePage })}
            icon={icon}
            variants={sortedVariants}
          />
        )}
      </div>
    </NoSSR>
  );
};

export default memo(CompareFab);
