import { createContext } from 'react';

import { PageFile } from 'types/models/PageFile';
import { ResolveConfig } from 'utils/resolveImageUrl';

export type FilesContextType = {
  getShopFiles: (names: (string | undefined)[]) => (PageFile['file'] | undefined)[];
  getPageFiles: (names: (string | undefined)[]) => (PageFile['file'] | undefined)[];
  getShopImageConfigs: (names: (string | undefined)[]) => (ResolveConfig | undefined)[];
  getPageImageConfigs: (names: (string | undefined)[]) => (ResolveConfig | undefined)[];
};

export const FilesContext = createContext<FilesContextType>({
  getShopFiles: () => [],
  getPageFiles: () => [],
  getShopImageConfigs: () => [],
  getPageImageConfigs: () => [],
});
