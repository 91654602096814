import i18n from './i18n';
import app from './app';
import itemLimits from './itemLimits';
import routes from './routes';
import analytics from './analytics';

const config = {
  ...app,
  ...i18n,
  ...itemLimits,
  ...routes,
  ...analytics,
  OVERLAY_ID: '__overlay',
};

export default config;
