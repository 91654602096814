import { createContext, ElementType } from 'react';
import { ImagePreset } from 'types/ImagePreset';

export type SkeletonImageContextType = {
  getSkeletonImage: (preset: ImagePreset, id?: number) => ElementType | undefined;
};

export const SkeletonImageContext = createContext<SkeletonImageContextType>({
  getSkeletonImage: () => undefined,
});
