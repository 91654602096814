import { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';

import config from 'config';
import { PaymentId } from 'types/models/PaymentMethod';
import { ShipmentId } from 'types/models/ShipmentMethod';
import { FormValues } from '@pages/CheckoutDeliveryPage/CheckoutDeliveryPage.types';
import { UserContext } from '@providers/UserProvider';
// import { defaultCreditCardValues } from '@components/CreditCard/CreditCard.consts';
// import { FormValues as CreditCardFormValues } from '@components/CreditCard/CreditCard.types';
import { UserTypeId } from '@components/CheckoutListOption';
import { getAddPaymentInfoPayload } from 'utils/analytics/payload/addPaymentInfo';
import { getAddShippingInfoPayload } from 'utils/analytics/payload/addShippingInfo';
import formatPhoneField from 'utils/formatPhoneField';
import useTrack from 'hooks/useTrack';

import { Props, GoToSummary } from './CheckoutProvider.types';
import { CheckoutContext, defaultValues } from './CheckoutProvider.context';

const { routes } = config;

const CheckoutProvider = ({ children }: Props): JSX.Element => {
  const router = useRouter();
  const { userData } = useContext(UserContext);
  const { trackLink } = useTrack();
  const { Provider } = CheckoutContext;

  const [initialValues, setInitialValues] = useState<FormValues>(defaultValues);
  // const [initialCreditCardValues, setInitialCreditCardValues] = useState<CreditCardFormValues>(defaultCreditCardValues);
  const [submittingOrder, setSubmittingOrder] = useState(false);
  const [activePaymentMethodId, setActivePaymentMethodId] = useState<PaymentId>('WIRE_TRANSFER');
  const [activeBankId, setActiveBankId] = useState<number>();
  const [activeShippingMethodId, setActiveShippingMethodId] = useState<ShipmentId>('COURIER');
  const [activeUserTypeId, setActiveUserTypeId] = useState<UserTypeId>('COMPANY');
  const [activeCompanyDataId, setActiveCompanyDataId] = useState<number>();
  const [activeShipmentId, setActiveShipmentId] = useState<number>();

  useEffect(() => {
    const resetProvider = (currentUrl: string) => {
      const previousUrl = router.asPath;
      const checkoutHrefs = [routes.checkout.delivery.href, routes.checkout.summary.href];
      if (checkoutHrefs.includes(previousUrl) && !checkoutHrefs.includes(currentUrl)) {
        setInitialValues({ ...defaultValues, termsOfUse: !!userData?.hasSeenLatestTermsOfSale });
        // setInitialCreditCardValues(defaultCreditCardValues);
        setSubmittingOrder(false);
      }
    };

    router.events.on('routeChangeComplete', resetProvider);

    return () => {
      router.events.off('routeChangeComplete', resetProvider);
    };
  }, [router.asPath]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInitialValues({ ...defaultValues, termsOfUse: !!userData?.hasSeenLatestTermsOfSale });
  }, [userData?.hasSeenLatestTermsOfSale]);

  const goToSummary: GoToSummary = (values, { totalPrice, products, paymentMethod, shippingMethod }) => {
    setInitialValues({
      ...values,
      phoneNumber: formatPhoneField(values.phoneNumber),
      additionalPhoneNumber: formatPhoneField(values.additionalPhoneNumber),
    });
    trackLink(
      'e-commerce',
      'add_payment_info',
      getAddPaymentInfoPayload(totalPrice, products, paymentMethod?.name)
    )(() => {
      trackLink(
        'e-commerce',
        'add_shipping_info',
        getAddShippingInfoPayload(totalPrice, products, shippingMethod?.name)
      )(() => {
        router.push(routes.checkout.summary.href);
      });
    });
  };

  return (
    <Provider
      value={{
        submittingOrder,
        activeUserTypeId,
        activeShippingMethodId,
        activePaymentMethodId,
        activeBankId,
        activeCompanyDataId,
        activeShipmentId,
        initialValues,
        // initialCreditCardValues,
        setInitialValues,
        // setInitialCreditCardValues,
        setActiveUserTypeId,
        setActiveShippingMethodId,
        setActivePaymentMethodId,
        setActiveBankId,
        setActiveCompanyDataId,
        setActiveShipmentId,
        setSubmittingOrder,
        goToSummary,
      }}
    >
      {children}
    </Provider>
  );
};

export default CheckoutProvider;
