import { defineMessages } from 'utils/intl';

export default defineMessages({
  errorType: 'Błąd {errorCode}',
  message404: 'Strona o podanym adresie nie istnieje',
  description404: 'Zajrzyj na naszą ',
  message403: 'Odmowa dostępu',
  description403: 'Nie posiadasz upoważnienia do przeglądania tej strony.\nZajrzyj na naszą ',
  message500: 'Coś poszło nie tak',
  description500: 'Spróbuj ponownie później lub zajrzyj na naszą\n',
  message503: 'Strona jest tymczasowo niedostępna',
  footer: 'Potrzebujesz pomocy? Zadzwoń: {phoneNumber}.',
  copyright: 'Copyright © {year} {appShortName}',
  indexPage: 'stronę główną',
});
