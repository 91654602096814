import { PHONE_NUMBER_FORMAT } from 'consts/validation';

const formatPhoneField = (phoneNumber: string): string => {
  const [, prefix, phone] = phoneNumber.match(PHONE_NUMBER_FORMAT) || [];

  if (!phone) {
    return '';
  }

  return `${prefix ? prefix.trim() : '+48'} ${phone}`;
};

export default formatPhoneField;
