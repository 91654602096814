// It must be JS and module.exports because next.config.js use this file

module.exports = {
  xSmall: 360,
  small: 768,
  medium: 992,
  largeMedium: 1160,
  large: 1280,
  xLarge: 1360,
};
