import { createContext } from 'react';

import ShopConfig from 'types/ShopConfig';
import ContactConfig from 'types/ContactConfig';

import { ShopPresence } from './ConfigProvider.types';

export type ConfigContextType = Required<ShopConfig & ShopPresence & { contact: ContactConfig }>;

export const ConfigContext = createContext<ConfigContextType>({
  shopId: '',
  appUrl: '',
  gaMeasurementId: '',
  ceneoAccountGuid: '',
  overriddenFooterUrl: '',
  cfCustomerSubdomain: '',
  searchMinExactMatches: 0,
  hasIndividualCustomers: false,
  isBiznesowe: false,
  isDellowo: false,
  isPremiumStation: false,
  isPremiumSerwer: false,
  isCocon: false,
  isFirewall: false,
  isRugged: false,
  contact: {} as ContactConfig,
  variantSelectorAlgorithm: 'default',
});
