export type PhoneVariant = 'landline' | 'mobile';
type PhoneFormat = [RegExp, string];

const formatPhone = (tel: string | number, variant: PhoneVariant, prefix = '+48'): string => {
  const mobile: PhoneFormat = [/(\d{3})(\d{3})(\d{3})/, `${prefix} $1 $2 $3`];
  const landline: PhoneFormat = [/(\d{2})(\d{3})(\d{2})(\d{2})/, `${prefix} $1 $2 $3 $4`];

  return tel.toString().replace(...(variant === 'mobile' ? mobile : landline));
};

export default formatPhone;
