import ApiPath from 'types/ApiPath';
import omit from 'utils/omit';
import pick from 'utils/pick';

export type RequestMethod = 'GET' | 'PUT' | 'POST' | 'DELETE';

class ApiRoute<R extends Record<string, unknown>, V extends Record<string, unknown> = Record<string, never>> {
  queryParamsKeys: (keyof V)[];

  constructor(
    readonly path: `/${string}`,
    readonly method: RequestMethod
  ) {
    this.queryParamsKeys = (path.match(/(:[^/]+)/g) || []).map((key) => key.replace(':', '')) as (keyof V)[];
  }

  getPath(variables = <V>{}) {
    const params = pick(variables, this.queryParamsKeys);

    return <ApiPath<R, V>>Object.keys(params).reduce((acc, key) => acc.replace(`:${key}`, `${params[key]}`), this.path);
  }

  getPayload(variables = <V>{}) {
    return <V>omit(variables, this.queryParamsKeys);
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export type GetResponse<C extends ApiRoute<any, any>> = C extends ApiRoute<infer R, any> ? R : never;
export type GetVariables<C extends ApiRoute<any, any>> = C extends ApiRoute<any, infer V> ? V : never;
/* eslint-enable @typescript-eslint/no-explicit-any */

export default ApiRoute;
