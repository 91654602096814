import { ReactNode } from 'react';

import { PageFile } from 'types/models/PageFile';
import { ResolveConfig } from 'utils/resolveImageUrl';

import { FilesContext } from './FilesProvider.context';

const { Provider } = FilesContext;

type Props = {
  shopFiles?: PageFile[];
  pageFiles?: PageFile[];
  children: ReactNode;
};

export const findFilesByName =
  (array: PageFile[]) =>
  (names: (string | undefined)[]): (PageFile['file'] | undefined)[] =>
    names.map((name) => array.find((pageFile) => pageFile.name === name)?.file);

export const findImageConfigByName =
  (array: PageFile[]) =>
  (names: (string | undefined)[]): (ResolveConfig | undefined)[] =>
    names.map((name) => {
      const file = array.find((pageFile) => pageFile.name === name)?.file;

      return file
        ? {
            preset: file.extension === 'svg' ? 'svg' : 'i',
            extension: file.extension,
            name: file.basename,
            width: file.width,
            height: file.height,
            productName: name,
          }
        : undefined;
    });

const FilesProvider = ({ shopFiles = [], pageFiles = [], children }: Props): JSX.Element => {
  const getShopFiles = findFilesByName(shopFiles);
  const getPageFiles = findFilesByName(pageFiles);
  const getShopImageConfigs = findImageConfigByName(shopFiles);
  const getPageImageConfigs = findImageConfigByName(pageFiles);

  return (
    <Provider value={{ getShopFiles, getPageFiles, getShopImageConfigs, getPageImageConfigs }}>{children}</Provider>
  );
};

export default FilesProvider;
