import { ElementType, ReactNode } from 'react';
import { ImagePreset } from 'types/ImagePreset';
import { ProductType } from 'types/models/ProductType';
import resolveImageUrl from 'utils/resolveImageUrl';

import { SkeletonImageContext, SkeletonImageContextType } from './SkeletonImageProvider.context';

const { Provider } = SkeletonImageContext;

type Props = {
  productTypes: Required<Pick<ProductType, 'id' | 'icon'>>[];
  children: ReactNode;
};

const SkeletonImageProvider = ({ productTypes, children }: Props): JSX.Element => {
  const getSkeletonImage: SkeletonImageContextType['getSkeletonImage'] = (preset: ImagePreset, id?: number) => {
    const type = productTypes?.find((productType) => productType.id === id);

    const Icon: ElementType | undefined = type?.icon
      ? ({ className, width = 48, height = 48 }) => (
          <img
            alt="skeleton"
            className={className}
            src={resolveImageUrl({ name: type.icon.basename, preset, extension: type.icon.extension })}
            width={width}
            height={height}
          />
        )
      : undefined;

    return Icon;
  };

  return (
    <Provider
      value={{
        getSkeletonImage,
      }}
    >
      {children}
    </Provider>
  );
};

export default SkeletonImageProvider;
