import { ErrorResponse } from 'utils/apiError';

import { Action } from './fetchReducer.types';

export const startFetching = <D>(): Action<D> => ({ type: 'FETCH/START' });
export const loadData = <D>(data?: D, headers?: Headers): Action<D> => ({
  type: 'FETCH/SUCCESS',
  payload: { data, headers },
});
export const handleError = <D>(error: ErrorResponse): Action<D> => ({
  type: 'FETCH/ERROR',
  payload: { error },
});
