import { useContext } from 'react';

import { IntlContext } from '@providers/IntlProvider';

const useIntl = () => {
  const { intl } = useContext(IntlContext);

  return intl;
};

export default useIntl;
