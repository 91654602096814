import { useContext } from 'react';

import config from 'config';
import { ConfigContext } from '@providers/ConfigProvider';
import ShopPhone from '@templates/ShopPhone';
import { FormattedMessage } from 'utils/intl';

import { Props } from './ErrorPage.types';
import { defaultErrorCode, errorMessageMap, errorIconMap, errorDescriptionMap } from './ErrorPage.consts';
import classes from './ErrorPage.module.scss';
import messages from './ErrorPage.messages';

const { routes } = config;

const ErrorPage = ({ errorCode = defaultErrorCode }: Props): JSX.Element => {
  const { contact } = useContext(ConfigContext);

  const message = errorMessageMap[errorCode];
  const description = errorDescriptionMap[errorCode];
  const ErrorIcon = errorIconMap[errorCode];

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainWrapper}>
        <ErrorIcon className={classes.icon} />
        <h1 className={classes.heading}>
          <FormattedMessage values={{ errorCode }} {...messages.errorType} />
        </h1>
        <p className={classes.message}>
          <FormattedMessage {...message} />
        </p>
        <p className={classes.description}>
          <FormattedMessage {...description} />
          <a className={classes.link} href={routes.home.href}>
            <FormattedMessage {...messages.indexPage} />
          </a>
          .
        </p>
      </div>
      <div className={classes.footer}>
        <div className={classes.phone}>
          <FormattedMessage
            {...messages.footer}
            values={{
              phoneNumber: <ShopPhone prefix="" withoutIcon />,
            }}
          />
        </div>
        <FormattedMessage
          {...messages.copyright}
          values={{
            year: new Date().getFullYear(),
            appShortName: contact.appShortName || '',
          }}
          tagName="div"
        />
      </div>
    </div>
  );
};

export default ErrorPage;
