import { useEffect } from 'react';
import { debounce } from 'debounce';

type Listener = <K extends keyof WindowEventMap>(this: Window, ev: WindowEventMap[K]) => void;

const useEventListener = <K extends keyof WindowEventMap>(type: K, listener: Listener): void => {
  useEffect(() => {
    const debouncedListener = debounce(listener, 100);

    window.addEventListener(type, debouncedListener);

    return () => {
      window.removeEventListener(type, debouncedListener);
    };
  }, [type, listener]);
};

export default useEventListener;
