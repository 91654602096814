export const copySearchParams = ['Control', 'b'];

export const copyRedirectTargetUrl = [
  ['Control', 'Shift', 'X'],
  ['Control', 'Shift', 'x'],
];

export const toggleAnalytics = [
  ['Control', 'Shift', '>'],
  ['Control', 'Shift', '.'],
];

export const copySpecification = [
  ['Control', 'Shift', 'S'],
  ['Control', 'Shift', 's'],
];
