import { CreateAddressRoute, GetUserAddressesRoute } from 'api/users/addresses';
import { DeleteAddressRoute, UpdateAddressRoute } from 'api/users/addresses/[id]';
import { SetPrimaryAddressRoute } from 'api/users/addresses/[id]/setPrimary';
import ApiRoute from 'utils/apiRoute';

export const getUserAddressesRoute: GetUserAddressesRoute = new ApiRoute('/users/addresses', 'GET');
export const createAddressRoute: CreateAddressRoute = new ApiRoute('/users/addresses', 'POST');

export const updateAddressRoute: UpdateAddressRoute = new ApiRoute('/users/addresses/:id', 'PUT');
export const deleteAddressRoute: DeleteAddressRoute = new ApiRoute('/users/addresses/:id', 'DELETE');
export const setPrimaryAddressRoute: SetPrimaryAddressRoute = new ApiRoute('/users/addresses/:id/setPrimary', 'PUT');
