import { useContext } from 'react';
import { useRouter } from 'next/router';
import jwtDecode from 'jwt-decode';
import isAfter from 'date-fns/isAfter';

import config from 'config';
import { DecodedToken } from 'types/DecodedToken';
import { createSessionRoute, refreshTokenRoute } from 'apiRoutes/session';
import { AuthContext } from '@providers/AuthProvider';
import { RefererType } from 'utils/getRefererType';
import { clientApiFetch } from 'utils/fetch';

const { routes } = config;
const pagesWithoutTokenCheck = [
  routes.checkout.summary.href,
  routes.checkout.confirmation.hash.href,
  routes.checkout.failure.hash.href,
  routes.checkout.ceneo.hash.href,
  routes.quotation.confirmation.id.href,
];

type UseTokenReturnType = {
  refreshInvalidToken: (url?: string) => Promise<void>;
  receiveToken: (
    currentGclid?: string,
    currentRefererType?: RefererType,
    initialIsNew?: boolean,
    entryPage?: string
  ) => Promise<void>;
  refreshToken: () => Promise<void>;
};

const useToken = (): UseTokenReturnType => {
  const { auth, markAsRefreshed, markAsRefreshing, checkIfSessionIsRefreshed } = useContext(AuthContext);
  const router = useRouter();

  const isInvalidToken = (): boolean => {
    const tokenData: Partial<DecodedToken['https://hasura.io/jwt/claims']> = auth.claims || {};
    const isStealthy = !!tokenData['x-hasura-uid'] && !tokenData['x-hasura-user-id'];
    const isNotActivated = !!tokenData['x-hasura-user-id'] && tokenData['x-hasura-is-active'] !== '1';

    return !!auth.claims && (isStealthy || isNotActivated);
  };

  const receiveToken = async (
    currentClid?: string,
    currentRefererType?: RefererType,
    initialIsNew?: boolean,
    entryPage?: string
  ): Promise<void> => {
    const variables = { clid: currentClid, referer: currentRefererType, entryPage };

    const isNew = initialIsNew || (!pagesWithoutTokenCheck.includes(router.pathname) && isInvalidToken());
    markAsRefreshing();
    auth.clear();

    const { data } = await clientApiFetch(isNew ? createSessionRoute : refreshTokenRoute, variables);
    auth.token = data?.token;

    markAsRefreshed();
  };

  const refreshInvalidToken = async (url?: string): Promise<void> => {
    await checkIfSessionIsRefreshed();
    const token = auth.token || '';
    let invalidToken = false;
    let tokenExpired = false;

    try {
      const decodedToken = token.includes('.') ? jwtDecode<DecodedToken>(token) : undefined;
      invalidToken = !!url && !pagesWithoutTokenCheck.includes(url) && isInvalidToken();
      tokenExpired = !!decodedToken && !invalidToken && isAfter(new Date().getTime(), decodedToken.exp * 1000);
    } catch {
      tokenExpired = true;
    }

    if (!token || tokenExpired || invalidToken) {
      await receiveToken(undefined, undefined, invalidToken);
    }
  };

  const refreshToken = async () => {
    const { data: refreshData } = await clientApiFetch(refreshTokenRoute);

    if (refreshData) {
      auth.token = refreshData?.token;
    }
  };

  return { refreshInvalidToken, receiveToken, refreshToken };
};

export default useToken;
