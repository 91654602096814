import { ReactNode } from 'react';

import { Page } from 'types/models/Page';

import { PageContext } from './PageProvider.context';

const { Provider } = PageContext;

type Props = {
  pages?: Page[];
  children: ReactNode;
};

const PageProvider = ({ pages = [], children }: Props): JSX.Element => {
  const [page = {}] = pages;
  return <Provider value={page}>{children}</Provider>;
};

export default PageProvider;
