import { CreateAccountRoute } from 'api/users';
import { ChangeEmailRoute, RequestEmailChangeRoute } from 'api/users/email';
import { LogoutRoute } from 'api/users/logout';
import { GetUserRoute } from 'api/users/me';
import { ChangePasswordRoute, ResetPasswordRoute } from 'api/users/password';
import { ResendActivationLinkRoute } from 'api/users/resendActivationLink';
import ApiRoute from 'utils/apiRoute';

export const createAccountRoute: CreateAccountRoute = new ApiRoute('/users', 'POST');
export const getUserRoute: GetUserRoute = new ApiRoute('/users/me', 'GET');
export const requestEmailChangeRoute: RequestEmailChangeRoute = new ApiRoute('/users/email', 'POST');
export const changeEmailRoute: ChangeEmailRoute = new ApiRoute('/users/email', 'PUT');
export const logoutRoute: LogoutRoute = new ApiRoute('/users/logout', 'POST');
export const resetPasswordRoute: ResetPasswordRoute = new ApiRoute('/users/password', 'POST');
export const changePasswordRoute: ChangePasswordRoute = new ApiRoute('/users/password', 'PUT');
export const resendActivationLinkRoute: ResendActivationLinkRoute = new ApiRoute('/users/resendActivationLink', 'POST');
