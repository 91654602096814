import { Fragment, ReactNode } from 'react';
import { MessageVariables } from 'utils/intl';
import keysOf from 'utils/keysOf';

export const splitToParts = (message: string, variables: MessageVariables = {}): ReactNode[] => {
  const matches = message.match(/\{[^}]+\}|[^{}]+/gm);
  return (matches?.map((match) => {
    const keyMatch = match.match(/^{([^}]+)}$/);
    return keyMatch ? variables[keyMatch[1]] : match;
  }) || []) as ReactNode[];
};

export const interpolateVariables = (
  message: string,
  variables: Record<string, unknown>,
  preserveJsx = false
): string | ReactNode[] =>
  preserveJsx
    ? splitToParts(message, variables).map((part, i) => <Fragment key={i}>{part}</Fragment>) // eslint-disable-line react/no-array-index-key
    : keysOf(variables).reduce(
        (acc, curr) => acc.replace(new RegExp(`{${curr}}`, 'g'), `${variables[curr] || ''}`),
        message
      );
