import { useContext } from 'react';
import { ConfigContext } from '@providers/ConfigProvider';
import PhoneNumber, { Props as PhoneNumberProps } from '@components/atoms/PhoneNumber';

type Props = Omit<PhoneNumberProps, 'tel' | 'variant'>;

const ShopPhone = (props: Props): JSX.Element => {
  const { contact } = useContext(ConfigContext);

  return (
    <PhoneNumber
      {...props}
      variant={contact.cellPhone ? 'mobile' : 'landline'}
      tel={contact.cellPhone || contact.landlinePhone}
    />
  );
};

export default ShopPhone;
