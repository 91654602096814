import { ElementType, Fragment, useContext, useMemo } from 'react';

import { IntlContext, MessageVariables } from '@providers/IntlProvider';
import { MessageDescriptor } from 'utils/intl';

type Props = {
  tagName?: ElementType;
  id?: MessageDescriptor['id'];
  description?: MessageDescriptor['description'];
  defaultMessage?: MessageDescriptor['defaultMessage'];
  values?: MessageVariables;
};

const FormattedMessage = ({
  tagName: Component = Fragment,
  id,
  description,
  defaultMessage,
  values,
}: Props): JSX.Element => {
  const { intl } = useContext(IntlContext);
  const message = useMemo(() => intl.formatMessageWithParts({ id, description, defaultMessage }, values), [id, values]); // eslint-disable-line react-hooks/exhaustive-deps

  return <Component>{message}</Component>;
};

export default FormattedMessage;
