import { useState, useEffect, ReactNode, useCallback, useContext } from 'react';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

import { UserData } from 'types/UserData';
import { Address, AddressType } from 'types/models/Address';
import {
  getUserRoute,
  createAccountRoute,
  changeEmailRoute,
  requestEmailChangeRoute,
  changePasswordRoute,
  resetPasswordRoute,
  resendActivationLinkRoute,
  logoutRoute,
} from 'apiRoutes/users';
import {
  createAddressRoute,
  deleteAddressRoute,
  updateAddressRoute,
  setPrimaryAddressRoute,
} from 'apiRoutes/users/addresses';
import { createSessionRoute, getSessionVarsRoute, updateSessionVarsRoute } from 'apiRoutes/session';

import { ConfigContext } from '@providers/ConfigProvider';
import { AuthContext } from '@providers/AuthProvider';
import getRefererType from 'utils/getRefererType';
// import { init as initAnalytics } from 'utils/analytics';
import useTrack from 'hooks/useTrack';
import useToken from 'hooks/useToken';
import useFetch, { FetchResult } from 'hooks/useFetch';

import { UserContext, UserContextType } from './UserProvider.context';

type Props = {
  userData?: UserData;
  connectingIp: string;
  withoutTokenRefresh?: boolean;
  children: ReactNode;
};

const getPurifiedPath = (path: string, query: ParsedUrlQuery): string => {
  const forbiddenParams = ['gclid', 'ceneo_cid', 'ceneo_std', 'ceneo_spo', 'utm_source', 'utm_medium'];

  return forbiddenParams.reduce(
    (acc, curr) => acc.replace(`&${curr}=${query[curr]}`, '').replace(`${curr}=${query[curr]}&`, ''),
    path
  );
};

const UserProvider = ({
  userData: initialUserData,
  connectingIp,
  withoutTokenRefresh = false,
  children,
}: Props): JSX.Element => {
  const { auth, isSessionRefreshed, markAsRefreshed, markAsRefreshing } = useContext(AuthContext);
  const [userData, setUserData] = useState<UserContextType['userData']>(initialUserData);
  const [sessionVars, setSessionVars] = useState<UserContextType['sessionVars']>();
  const [areSessionVarsRefreshed, setAreSessionRefreshed] = useState<UserContextType['areSessionVarsRefreshed']>(false);
  const isLoggedIn = useCallback<UserContextType['isLoggedIn']>(() => !!auth.jwt && !!auth.userId, []); // eslint-disable-line react-hooks/exhaustive-deps
  const [getUser] = useFetch(getUserRoute);
  const [logoutMutation] = useFetch(logoutRoute);
  const [createSession] = useFetch(createSessionRoute, { withoutTokenRefresh: true });
  const [register] = useFetch(createAccountRoute);
  const [createAddress] = useFetch(createAddressRoute);
  const [setPrimaryAddressMutation] = useFetch(setPrimaryAddressRoute);
  const [updateAddress] = useFetch(updateAddressRoute);
  const [removeAddress] = useFetch(deleteAddressRoute);
  const [resendActivationLink] = useFetch(resendActivationLinkRoute);
  const [resetPassword] = useFetch(resetPasswordRoute);
  const [changePassword] = useFetch(changePasswordRoute);
  const [requestEmailChangeMutation] = useFetch(requestEmailChangeRoute);
  const [changeEmailMutation] = useFetch(changeEmailRoute);
  const [getSessionVars] = useFetch(getSessionVarsRoute);
  const [updateSessionVars] = useFetch(updateSessionVarsRoute);
  const router = useRouter();
  const { appUrl } = useContext(ConfigContext);
  const { receiveToken } = useToken();
  const { trackPageView } = useTrack();
  const { Provider } = UserContext;

  const handleCreateSessionResponse = async (
    result: FetchResult<typeof createSessionRoute>,
    fetchUser: UserContextType['fetchUser']
  ) => {
    const token = result?.data?.token;

    if (token && token.includes('.')) {
      auth.token = token;
      markAsRefreshed();

      if (auth.userId) {
        await fetchUser();
      }
    }
  };

  const fetchUser: UserContextType['fetchUser'] = async () => {
    setAreSessionRefreshed(false);

    const result = await getUser();

    setUserData(result?.data?.user);
    setSessionVars(result?.data?.sessionVars);
    setAreSessionRefreshed(true);

    return result;
  };

  const login: UserContextType['login'] = async (variables) => {
    const result = await createSession(variables);

    await handleCreateSessionResponse(result, fetchUser);

    return result;
  };

  const logout: UserContextType['logout'] = async (withLogoutMutation = true) => {
    if (withLogoutMutation) {
      await logoutMutation();
    }
    markAsRefreshing();
    auth.clear();
    const result = await createSession();

    await handleCreateSessionResponse(result, fetchUser);
    setUserData(undefined);

    return result;
  };

  const editAddress: UserContextType['updateAddress'] = async (
    id: number,
    variables: Omit<Address, 'id' | 'typeId' | 'userAddresses'>
  ) => {
    const result = await updateAddress({
      ...variables,
      id,
    });
    return result;
  };

  const setPrimaryAddress: UserContextType['setPrimaryAddress'] = async (id: number, type: AddressType) => {
    const result = await setPrimaryAddressMutation({ id, type });

    return result;
  };

  const deleteAddress: UserContextType['deleteAddress'] = async (id: number) => {
    const result = await removeAddress({ id });

    return result;
  };

  const editUser: UserContextType['editUser'] = async ({ firstName, lastName, phone }) => {
    if (userData) {
      await editAddress(userData.id, { first_name: firstName, last_name: lastName, phone });
      await fetchUser();
    }
  };

  const requestEmailChange: UserContextType['requestEmailChange'] = async ({ newEmail }) => {
    if (userData) {
      return requestEmailChangeMutation({ newEmail });
    }

    return undefined;
  };

  const editEmail: UserContextType['editEmail'] = async ({ token }) => {
    if (userData) {
      const mutation = await changeEmailMutation({ token });
      await fetchUser();
      return mutation;
    }

    return undefined;
  };

  const getSessionVar: UserContextType['getSessionVar'] = (key) => sessionVars?.[key];

  const setSessionVar: UserContextType['setSessionVar'] = async (key, value) => {
    if (!auth.jwt) {
      return null;
    }
    const { data } = await getSessionVars();
    const newSessionVars = {
      ...(data?.sessionVars || {}),
      [key]: value,
    };
    setSessionVars(newSessionVars);
    return updateSessionVars({ sessionVars: newSessionVars });
  };

  const refreshSessionVars: UserContextType['refreshSessionVars'] = async () => {
    if (!auth.jwt) {
      return { data: undefined };
    }
    setAreSessionRefreshed(false);
    const result = await getSessionVars();
    setSessionVars(result.data?.sessionVars || {});
    setAreSessionRefreshed(true);
    return result;
  };

  useEffect(() => {
    const init = async () => {
      const initGclid = router.query.gclid as string | undefined;
      const initCeneoCid = router.query.ceneo_cid as string | undefined;
      const initClid = initGclid || initCeneoCid || document.referrer;
      const refType = getRefererType(router.query, document.referrer);

      if (!withoutTokenRefresh) {
        await receiveToken(
          refType !== 'unknown' || !document.referrer.includes(appUrl) ? initClid : undefined,
          refType,
          false,
          getPurifiedPath(router.asPath, router.query)
        );
        await setSessionVar('clientData', {
          height: window.screen.height,
          width: window.screen.width,
          userAgent: navigator.userAgent,
          ip: connectingIp,
        });
        trackPageView(router.asPath, '');
      } else {
        markAsRefreshed();
      }

      // initAnalytics(initClid);
      setAreSessionRefreshed(true);
    };
    init();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (initialUserData) {
      setUserData(initialUserData);
    }
  }, [initialUserData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Provider
      value={{
        isLoggedIn,
        userData,
        sessionVars,
        isSessionRefreshed,
        areSessionVarsRefreshed,
        register,
        login,
        logout,
        fetchUser,
        createAddress,
        updateAddress: editAddress,
        editUser,
        editEmail,
        setPrimaryAddress,
        deleteAddress,
        resendActivationLink,
        resetPassword,
        changePassword,
        requestEmailChange,
        getSessionVar,
        setSessionVar,
        refreshSessionVars,
      }}
    >
      {children}
    </Provider>
  );
};

export default UserProvider;
